import {cssVal, getDataByCodePostal, isEmpty, newInput, texteForSiege, tryToDate} from "@/data/helpers";
import {def} from "@/data/dictionnaire";


export const changementNomAvecEnfantMineur = {
    title: "Changement de nom avec enfant(s) mineur(s)",
    texte: "<strong>{{demandeurGroup.civilite}} {{demandeurGroup.prenom}} {{demandeurGroup.nom}}</strong> né le {{demandeurGroup.dateNaissance}} à {{demandeurGroup.lieuNaissance}} ({{demandeurGroup.departementStr}}) [[et <strong>{{codemandeurGroup.civilite}} {{codemandeurGroup.prenom}} {{codemandeurGroup.nom}}</strong> né le {{codemandeurGroup.dateNaissance}} à {{codemandeurGroup.lieuNaissance}} ({{codemandeurGroup.departementStr}})]], {{lieuResidenceGroup}}, agissant tant en son nom qu'au nom de {{enfantGroup_1}}, [[{{enfantGroup_2}}, [[{{enfantGroup_3}}, [[{{enfantGroup_4}}, [[{{enfantGroup_5}}, ]]]]]]]] dépose auprès du Garde des Sceaux une requête afin de substituer à leur nom patronymique celui de <strong>{{ModificationGroup.nouveauPatronyme}}</strong>.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "demandeurGroup.dateNaissance":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            case "enfantGroup_1":
                if(isEmpty(varText['enfantGroup_2.nom'])) {
                    tmpText = ` son enfant mineur: ${cssVal(varText['enfantGroup_1.prenom'])} ${cssVal(varText['enfantGroup_1.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_1.dateNaissance']))} à ${cssVal(varText['enfantGroup_1.lieuNaissance'])} (${cssVal(varText['enfantGroup_1.departementStr'])})`;
                } else {
                    tmpText = ` ses enfants mineurs: ${cssVal(varText['enfantGroup_1.prenom'])} ${cssVal(varText['enfantGroup_1.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_1.dateNaissance']))} à ${cssVal(varText['enfantGroup_1.lieuNaissance'])} (${cssVal(varText['enfantGroup_1.departementStr'])})`;
                }
                break;
            case "enfantGroup_2":
                if(!isEmpty(varText['enfantGroup_2.prenom']) || !isEmpty(varText['enfantGroup_2.nom'])) {
                    tmpText = ` ${cssVal(varText['enfantGroup_2.prenom'])} ${cssVal(varText['enfantGroup_2.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_2.dateNaissance']))} à ${cssVal(varText['enfantGroup_2.lieuNaissance'])} (${cssVal(varText['enfantGroup_2.departementStr'])})`;
                }
                break;
            case "enfantGroup_3":
                if(!isEmpty(varText['enfantGroup_3.prenom']) || !isEmpty(varText['enfantGroup_3.nom'])) {
                    tmpText = ` ${cssVal(varText['enfantGroup_3.prenom'])} ${cssVal(varText['enfantGroup_3.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_3.dateNaissance']))} à ${cssVal(varText['enfantGroup_3.lieuNaissance'])} (${cssVal(varText['enfantGroup_3.departementStr'])})`;
                }
                break;
            case "enfantGroup_4":
                if(!isEmpty(varText['enfantGroup_4.prenom']) || !isEmpty(varText['enfantGroup_4.nom'])) {
                    tmpText = ` ${cssVal(varText['enfantGroup_4.prenom'])} ${cssVal(varText['enfantGroup_4.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_4.dateNaissance']))} à ${cssVal(varText['enfantGroup_4.lieuNaissance'])} (${cssVal(varText['enfantGroup_4.departementStr'])})`;
                }
                break;
            case "enfantGroup_5":
                if(!isEmpty(varText['enfantGroup_5.prenom']) || !isEmpty(varText['enfantGroup_5.nom'])) {
                    tmpText = ` ${cssVal(varText['enfantGroup_5.prenom'])} ${cssVal(varText['enfantGroup_5.nom'])}, né(e) le ${cssVal(tryToDate(varText['enfantGroup_5.dateNaissance']))} à ${cssVal(varText['enfantGroup_5.lieuNaissance'])} (${cssVal(varText['enfantGroup_5.departementStr'])})`;
                }
                break;
            case "lieuResidenceGroup":
                tmpText = texteForSiege(varText, 'lieuResidenceGroup');
                break;
            case "demandeurGroup.civilite":
                if(varText['demandeurGroup.civilite'] === 'homme') {
                    tmpText = cssVal('M.');
                } else if(varText['demandeurGroup.civilite'] === 'femme'){
                    tmpText = cssVal('Mme');
                }
                break;
            case "codemandeurGroup.civilite":
                if(varText['codemandeurGroup.civilite'] === 'homme') {
                    tmpText = cssVal('M.');
                } else if(varText['codemandeurGroup.civilite'] === 'femme'){
                    tmpText = cssVal('Mme');
                }
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        lieuResidenceGroup: [{}]
    },
    schema: [
        {
            //0
            name: "demandeurGroup",
            label: "Demandeur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //1
            label: "+ Ajouter un Co-demandeur",
            type: "button",
            on: {
                click: () => {
                    changementNomAvecEnfantMineur.schema[1].type = "hidden";
                    changementNomAvecEnfantMineur.schema[1].label = "";

                    changementNomAvecEnfantMineur.schema[2].type = "group";
                    changementNomAvecEnfantMineur.schema[2].label = "Demandeur 2";

                    changementNomAvecEnfantMineur.schema[3].type = "button";
                    changementNomAvecEnfantMineur.schema[3].label = "- Supprimer le Co-demandeur";
                }
            }
        },
        {
            //2
            name: "codemandeurGroup",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //3
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    changementNomAvecEnfantMineur.schema[2].value = [{}];

                    changementNomAvecEnfantMineur.schema[1].type = "button";
                    changementNomAvecEnfantMineur.schema[1].label = "+ Ajouter un Co-demandeur";

                    changementNomAvecEnfantMineur.schema[2].type = "hidden";
                    changementNomAvecEnfantMineur.schema[2].label = "";

                    changementNomAvecEnfantMineur.schema[3].type = "hidden";
                    changementNomAvecEnfantMineur.schema[3].label = "";
                }
            }
        },
        {
            //4
            name: "lieuResidenceGroup",
            label: "Lieu de Résidence",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            changementNomAvecEnfantMineur.schema[4].children[3].options = data.options;
                            changementNomAvecEnfantMineur.model.lieuResidenceGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },

        /* --------------------------------------------------------------- */
        {
            //5
            name: "enfantGroup_1",
            label: "Enfant 1",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //6
            label: "+ Ajouter un enfant",
            type: "button",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le group apparait
                     * 3. le btn del apparait
                     * 4. le new btn add apparait
                     * */
                    //btn add
                    changementNomAvecEnfantMineur.schema[6].type = "hidden";
                    changementNomAvecEnfantMineur.schema[6].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[7].type = "group";
                    changementNomAvecEnfantMineur.schema[7].label = "Enfant 2";

                    //btn del
                    changementNomAvecEnfantMineur.schema[8].type = "button";
                    changementNomAvecEnfantMineur.schema[8].label = "- Supprimer un enfant";

                    //btn add
                    changementNomAvecEnfantMineur.schema[9].type = "button";
                    changementNomAvecEnfantMineur.schema[9].label = "+ Ajouter un enfant";
                }
            }
        },
        {
            //7
            name: "enfantGroup_2",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //8 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * */
                    //btn del this
                    changementNomAvecEnfantMineur.schema[8].type = "hidden";
                    changementNomAvecEnfantMineur.schema[8].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[7].type = "hidden";
                    changementNomAvecEnfantMineur.schema[7].label = "";

                    //new btn add
                    changementNomAvecEnfantMineur.schema[9].type = "hidden";
                    changementNomAvecEnfantMineur.schema[9].label = "";

                    //old btn add
                    changementNomAvecEnfantMineur.schema[6].type = "button";
                    changementNomAvecEnfantMineur.schema[6].label = "+ Ajouter un enfant";
                }
            }
        },
        {
            //9 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * 5. le new btn del apparait
                     * */
                    //btn add
                    changementNomAvecEnfantMineur.schema[9].type = "hidden";
                    changementNomAvecEnfantMineur.schema[9].label = "";

                    //btn del
                    changementNomAvecEnfantMineur.schema[8].type = "hidden";
                    changementNomAvecEnfantMineur.schema[8].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[10].type = "group";
                    changementNomAvecEnfantMineur.schema[10].label = "Enfant 3";

                    //btn add
                    changementNomAvecEnfantMineur.schema[12].type = "button";
                    changementNomAvecEnfantMineur.schema[12].label = "+ Ajouter un enfant";

                    //btn del
                    changementNomAvecEnfantMineur.schema[11].type = "button";
                    changementNomAvecEnfantMineur.schema[11].label = "- Supprimer un enfant";
                }
            }
        },
        {
            //10
            name: "enfantGroup_3",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //11 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    changementNomAvecEnfantMineur.schema[11].type = "hidden";
                    changementNomAvecEnfantMineur.schema[11].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[10].type = "hidden";
                    changementNomAvecEnfantMineur.schema[10].label = "";

                    //new btn add
                    changementNomAvecEnfantMineur.schema[12].type = "hidden";
                    changementNomAvecEnfantMineur.schema[12].label = "";

                    //old btn add
                    changementNomAvecEnfantMineur.schema[9].type = "button";
                    changementNomAvecEnfantMineur.schema[9].label = "+ Ajouter un enfant";

                    //old btn del
                    changementNomAvecEnfantMineur.schema[8].type = "button";
                    changementNomAvecEnfantMineur.schema[8].label = "- Supprimer un enfant";
                }
            }
        },
        {
            //12 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * 5. le new btn del apparait
                     * */
                    //btn add
                    changementNomAvecEnfantMineur.schema[12].type = "hidden";
                    changementNomAvecEnfantMineur.schema[12].label = "";

                    //btn del
                    changementNomAvecEnfantMineur.schema[11].type = "hidden";
                    changementNomAvecEnfantMineur.schema[11].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[13].type = "group";
                    changementNomAvecEnfantMineur.schema[13].label = "Enfant 4";

                    //btn add
                    changementNomAvecEnfantMineur.schema[15].type = "button";
                    changementNomAvecEnfantMineur.schema[15].label = "+ Ajouter un enfant";

                    //btn del
                    changementNomAvecEnfantMineur.schema[14].type = "button";
                    changementNomAvecEnfantMineur.schema[14].label = "- Supprimer un enfant";
                }
            }
        },
        {
            //13
            name: "enfantGroup_4",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //14 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    changementNomAvecEnfantMineur.schema[14].type = "hidden";
                    changementNomAvecEnfantMineur.schema[14].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[13].type = "hidden";
                    changementNomAvecEnfantMineur.schema[13].label = "";

                    //new btn add
                    changementNomAvecEnfantMineur.schema[15].type = "hidden";
                    changementNomAvecEnfantMineur.schema[15].label = "";

                    //old btn add
                    changementNomAvecEnfantMineur.schema[12].type = "button";
                    changementNomAvecEnfantMineur.schema[12].label = "+ Ajouter un enfant";

                    //old btn del
                    changementNomAvecEnfantMineur.schema[11].type = "button";
                    changementNomAvecEnfantMineur.schema[11].label = "- Supprimer un enfant";
                }
            }
        },
        {
            //15 btn add
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn add disparait
                     * 2. le old btn del disparait
                     * 3. le group apparait
                     * 4. le new btn add apparait
                     * */
                    //btn add
                    changementNomAvecEnfantMineur.schema[15].type = "hidden";
                    changementNomAvecEnfantMineur.schema[15].label = "";

                    //btn del
                    changementNomAvecEnfantMineur.schema[14].type = "hidden";
                    changementNomAvecEnfantMineur.schema[14].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[16].type = "group";
                    changementNomAvecEnfantMineur.schema[16].label = "Enfant 5";

                    //btn del
                    changementNomAvecEnfantMineur.schema[17].type = "button";
                    changementNomAvecEnfantMineur.schema[17].label = "- Supprimer un enfant";
                }
            }
        },
        {
            //16
            name: "enfantGroup_5",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //17 btn del
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    /**
                     * 1. le btn del disparait
                     * 2. le group disparait
                     * 3. le new btn add disparait
                     * 4. le old btn add apparait
                     * 5. le old btn del apparait
                     * */
                    //btn del this
                    changementNomAvecEnfantMineur.schema[17].type = "hidden";
                    changementNomAvecEnfantMineur.schema[17].label = "";

                    //group 2
                    changementNomAvecEnfantMineur.schema[16].type = "hidden";
                    changementNomAvecEnfantMineur.schema[16].label = "";

                    //old btn add
                    changementNomAvecEnfantMineur.schema[15].type = "button";
                    changementNomAvecEnfantMineur.schema[15].label = "+ Ajouter un enfant";

                    //old btn del
                    changementNomAvecEnfantMineur.schema[14].type = "button";
                    changementNomAvecEnfantMineur.schema[14].label = "- Supprimer un enfant";
                }
            }
        },

        {
            //18
            name: "ModificationGroup",
            label: "modification",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nouveauPatronyme, '_required')
            ]
        }
    ]
}